import { default as indexRq3pHwvTeIMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/index.vue?macro=true";
import { default as login5Gp14YEN9tMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/login.vue?macro=true";
import { default as _91id_93RzkiYFlyGUMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/[id].vue?macro=true";
import { default as createaxVKPCzAUcMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/create.vue?macro=true";
import { default as index6wGko4htavMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/index.vue?macro=true";
import { default as print_45jobsoWAnoz3L1SMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/print-jobs.vue?macro=true";
import { default as indexGHJ8qii7WhMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/reservations/index.vue?macro=true";
import { default as lanesdEntVTh83TMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/reservations/lanes.vue?macro=true";
import { default as settlementdHGhKc81ZoMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/settlement.vue?macro=true";
import { default as setupc4dtgQRkXXMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/setup.vue?macro=true";
import { default as editl7aJCoXvoDMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexB38dEAXAJWMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createEVLwHYmQEqMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/create.vue?macro=true";
import { default as indexoQKS23uf5oMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statusesZNgkMCQcc8Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses.vue?macro=true";
import { default as indexESlno9SwsMMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/index.vue?macro=true";
import { default as createnurpkEgj3yMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/statuses/create.vue?macro=true";
import { default as createKeQmLFkx7eMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/transfers/create.vue?macro=true";
import { default as indexqrVQH3qTRDMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitGSYz4O7LQ2Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93mO8uDnMRyUMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createYSYgFlrD6fMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93moOEvLmEMeMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId].vue?macro=true";
import { default as indexhXeOim8swXMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/index.vue?macro=true";
import { default as indexeUud9UaVQFMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitk4VmRy2i37Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93CHZekMYO6CMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id].vue?macro=true";
import { default as createu5hSUvOgYRMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/create.vue?macro=true";
import { default as indexRfucSSKeRUMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login5Gp14YEN9tMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupc4dtgQRkXXMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statusesZNgkMCQcc8Meta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93mO8uDnMRyUMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CHZekMYO6CMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20241213122323/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]